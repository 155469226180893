import { JSX } from 'solid-js'
import { getFontCSS } from '~/services/fonts/font-css'
import { Gradient, TextStyles } from '~/types/editor/text-styles'

const getTextObjectStyle = (textStyle: TextStyles) => {
  const css = getFontCSS(textStyle.props.fontVariant)
  const svgStyles = {
    fontFamily: css['font-family'],
    fontWeight: css['font-weight'],
    fontSize: textStyle.props.fontSize,
    lineHeight: textStyle.props.lineHeight,
    fill: textStyle.props.fill,
    textAlign: textStyle.props.textAlign,
    charSpacing: textStyle.props.letterSpacing * textStyle.props.fontSize * 32
  }

  return svgStyles
}

const getSVGStyle = (textStyle: TextStyles) => {
  const fontCSS = getFontCSS(textStyle.props.fontVariant)

  const baseTextStyle: JSX.CSSProperties & { gradient?: string } = {
    'font-family': fontCSS['font-family'],
    'font-style': fontCSS['font-style'],
    'font-weight': fontCSS['font-weight'],
    'font-size': `${textStyle.props.fontSize}px`,
    'letter-spacing': `${textStyle.props.letterSpacing * textStyle.props.fontSize}px`
  }

  const mainTextStyle = baseTextStyle
  if (typeof textStyle.props.fill === 'string'){
    mainTextStyle['fill'] = textStyle.props.fill
  }else{
    // returns the linear gradient def as string
    const id = `${textStyle.path}-gradient`
    const angle = textStyle.props.fill.angle
    const x1 = Math.cos(angle * Math.PI / 180);
    const x2 = Math.cos((angle + 180) * Math.PI / 180);
    const y1 = Math.sin(angle * Math.PI / 180);
    const y2 = Math.sin((angle + 180) * Math.PI / 180);
    mainTextStyle['gradient'] = `
      <linearGradient
        id="${id}"
        x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}"
      >
        ${textStyle.props.fill.colorStops.map(stop => (
          `<stop offset="${stop.offset * 100}%" stop-color="${stop.color}" />`
        ))}
      </linearGradient>
    `
    mainTextStyle['fill'] = `url(#${id})`
  }

  const strokesStyles: JSX.CSSProperties[] = textStyle.strokes.map(stroke => ({
    ...baseTextStyle,
    fill: stroke.color,
    stroke: stroke.color,
    'stroke-width': `${stroke.width}px`,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round'
  }))

  const shadowsStyles: JSX.CSSProperties[] = textStyle.shadows.map(shadow => ({
    ...baseTextStyle,
    'filter': `drop-shadow(${shadow.offsetX}px ${shadow.offsetY}px ${shadow.blur}px ${shadow.color})`,
    fill: shadow.color
  }))

  return {
    mainTextStyle,
    strokesStyles,
    shadowsStyles
  }
}

const getGradientCSS = (gradient: Gradient) => {
  const colorStops = gradient.colorStops.map(stop => `${stop.color} ${stop.offset*100}%`).join(',')
  return {
    'background-image': `${gradient.type}-gradient(90deg, ${colorStops})`
  }
}

export { getTextObjectStyle, getSVGStyle, getGradientCSS }